import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout';
import Banner from '../components/banner';
import HeadingBlock from '../components/headingBlock';
import SEO from '../components/seo';
import Welcome from '../components/welcome';

import CoFunderSEOImage from '../assets/images/banner-cofunders.jpg';
import merckLogo from '../assets/images/merck-logo.png';
import burkeLogo from '../assets/images/burke-logo.png';
import fondationChanelLogo from '../assets/images/fondation-chanel-logo.png';
import gkffLogo from '../assets/images/george-kaiser-family-foundation.jpg';
import CHAPLogo from '../assets/images/CHAP-logo.png';
import nicholsonLogo from '../assets/images/nicholson-logo.png';
import rhiaLogo from '../assets/images/rhia-logo.png';
import kelloggLogo from '../assets/images/wkkellogg-logo.png';
import skyline from '../assets/images/skyline.png';
import ExternalLink from '../components/externalLink';

class CoFunders extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query CoFunderMeta {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Layout page='co-funders'>
              <SEO
                title='Co-Funders'
                description='Grants awarded by Safer Childbirth Cities initiative represent the collaborative grantmaking of several philanthropic partners, collectively powering progress for maternal health.'
                image={CoFunderSEOImage}
                keywords={[``]}
              />
              <Banner
                title='Co-Funders'
                subTitle={`Grants awarded by Safer Childbirth Cities initiative represent the collaborative grantmaking of several philanthropic partners, collectively powering progress for maternal health.`}
                titleColor='#FFFFFF'
                subTitleColor='#FFFFFF'
                hasArrow={true}
              />
              <HeadingBlock title='Founder &amp; Secretariat' reverse={true} />
              <section className='darkBlueColorBkgd'>
                <Container>
                  <div className='spacer100 hidden-md hidden-lg'></div>
                  <Row>
                    <Col xs='9' sm='9' md='9' lg='9'>
                      <div className='spacer60 hidden-xs hidden-sm'></div>
                      <h2 className='whiteColor'>Merck for Mothers</h2>
                      <p className='whiteColor'>
                        Merck for Mothers is Merck’s $500 million initiative to
                        help create a world where no woman has to die while
                        giving life. Applying Merck’s business and scientific
                        resources, we collaborate with partners to improve the
                        health and well-being of women during pregnancy,
                        childbirth and the postpartum period. To date, Merck for
                        Mothers has supported efforts in nearly fifty countries
                        to help women have healthier pregnancies and safer
                        deliveries, including in the U.S. Merck for Mothers’
                        approach is to support the design of innovative models
                        to improve maternal health outcomes, testing of their
                        effectiveness and feasibility and, if successful, to
                        advocate for them to be scaled. For more information,
                        visit{' '}
                        <a
                          href='https://www.merckformothers.com/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          www.merckformothers.com
                        </a>
                        .
                      </p>
                      <div className='spacer60'></div>
                    </Col>
                    <Col xs='3' sm='3' md='3' lg='3'>
                      <div className='spacer100 hidden-xs hidden-sm'></div>
                      <img
                        className='img-responsive'
                        src={merckLogo}
                        alt='Merck for Mothers'
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
              <HeadingBlock title='Co-Funders' />
              <section>
                <Container>
                  <div className='spacer100 hidden-md hidden-lg'></div>
                  <Row>
                    <Col xs='9' sm='9' md='9' lg='9'>
                      <div className='spacer60 hidden-xs hidden-sm'></div>
                      <h2 className='blueColor'>The Burke Foundation</h2>
                      <p>
                        The Burke Foundation is a private foundation based in
                        Princeton, New Jersey. It funds transformative maternal
                        health and early childhood initiatives to foster the
                        healthy development of families and in underserved
                        communities in New Jersey. The Burke Foundation focuses
                        on three key areas: healthy pregnancies and births,
                        nurturing positive parent-child relationships, and
                        high-quality early care and learning. Learn more at{' '}
                        <ExternalLink href='http://www.burkefoundation.org/'>
                          <span class='ext-link'>www.burkefoundation.org</span>
                        </ExternalLink>
                        .
                      </p>
                      <div className='spacer30'></div>
                    </Col>
                    <Col xs='3' sm='3' md='3' lg='3'>
                      <div className='spacer60 hidden-xs hidden-sm'></div>
                      <img
                        className='img-responsive'
                        src={burkeLogo}
                        alt='The Burke Foundation'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='9' sm='9' md='9' lg='9'>
                      <div className='spacer30 hidden-xs hidden-sm'></div>
                      <h2 className='blueColor'>
                        The Community Health Acceleration Partnership
                      </h2>
                      <p>
                        For nearly a decade, a devoted collection of
                        philanthropists – Jeff Walker, English Sall and Austin
                        Hearst – has sought to deliver creativity, clarity and
                        energy to the field of community health, so as to help
                        countries develop sustainable, integrated, effective
                        community-health systems. Their dedication led to the
                        formation of the Community Health Acceleration
                        Partnership (CHAP). Through strategic engagement and
                        catalytic investments, CHAP works with partners to build
                        stronger community-health systems, both globally and in
                        the United States. They supported the first round of
                        grants of the Safer Childbirth Cities Initiative. For
                        more information, visit{' '}
                        <ExternalLink href='http://www.chap.health/'>
                          <span class='ext-link'>www.chap.health</span>.{' '}
                        </ExternalLink>
                      </p>
                      <div className='spacer30'></div>
                    </Col>
                    <Col xs='3' sm='3' md='3' lg='3'>
                      <img
                        className='img-responsive'
                        src={CHAPLogo}
                        alt='The Community Health Acceleration Partnership'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='9' sm='9' md='9' lg='9'>
                      <div className='spacer30 hidden-xs hidden-sm'></div>
                      <h2 className='blueColor'>Fondation CHANEL</h2>
                      <p>
                        Fondation CHANEL was created in 2011 to improve the
                        economic and social conditions of women and adolescent
                        girls around the world. It operates in over 30 countries
                        worldwide, including a strong engagement in the United
                        States on the intersecting issues of economic security,
                        health and social protection and women’s leadership. For
                        more information, visit{' '}
                        <ExternalLink href='http://www.fondationchanel.org/fr/'>
                          <span class='ext-link'>www.fondationchanel.org</span>
                        </ExternalLink>
                        .
                      </p>
                      <div className='spacer30'></div>
                    </Col>
                    <Col xs='3' sm='3' md='3' lg='3'>
                      <img
                        className='img-responsive'
                        src={fondationChanelLogo}
                        alt='Fondation CHANEL'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='9' sm='9' md='9' lg='9'>
                      <div className='spacer30 hidden-xs hidden-sm'></div>
                      <h2 className='blueColor'>
                        George Kaiser Family Foundation
                      </h2>
                      <p>
                        George Kaiser Family Foundation is a charitable
                        organization dedicated to providing equal opportunity to
                        young children in Tulsa through investments in early
                        childhood education, community health, social services
                        and civic enhancement. Learn more at{' '}
                        <ExternalLink href='http://www.gkff.org/'>
                          <span class='ext-link'>www.gkff.org</span>
                        </ExternalLink>
                        .
                      </p>
                      <div className='spacer30'></div>
                    </Col>
                    <Col xs='3' sm='3' md='3' lg='3'>
                      <img
                        className='img-responsive'
                        src={gkffLogo}
                        alt='George Kaiser Family Foundation'
                      />
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col xs='9' sm='9' md='9' lg='9'>
                      <div className='spacer30 hidden-xs hidden-sm'></div>
                      <h2 className='blueColor'>The Nicholson Foundation</h2>
                      <p>
                        The Nicholson Foundation is dedicated to improving the
                        health and well-being of vulnerable populations in New
                        Jersey. The Foundation’s primary goal is to improve
                        outcomes in health and early childhood. The Nicholson
                        Foundation’s strategic approach is to transform service
                        delivery systems by informing policy and developing
                        sustainable models that better serve vulnerable
                        populations. For more information, visit{' '}
                        <ExternalLink href='http://www.thenicholsonfoundation.org/'>
                          <span class='ext-link'>
                            www.thenicholsonfoundation.org
                          </span>
                        </ExternalLink>
                        .
                      </p>
                      <div className='spacer30'></div>
                    </Col>
                    <Col xs='3' sm='3' md='3' lg='3'>
                      <img
                        className='img-responsive'
                        src={nicholsonLogo}
                        alt='The Nicholson Foundation'
                      />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col xs='9' sm='9' md='9' lg='9'>
                      <div className='spacer30 hidden-xs hidden-sm'></div>
                      <h2 className='blueColor'>Rhia Ventures</h2>
                      <p>
                        Rhia Ventures is a women-led social investment
                        organization that seeks to create a vibrant market for
                        women’s sexual, reproductive and maternal health in the
                        US. Rhia fosters market development through three
                        complementary areas of work: direct investment via its
                        for-profit investment Fund; market-level research and
                        technical assistance to the Fund; and field-level and
                        corporate advocacy and engagement. Rhia's work will not
                        be done until all women and pregnant people in the U.S.
                        enjoy strong, equitable maternal and reproductive health
                        outcomes. For more information, visit{' '}
                        <ExternalLink href='http://www.rhiaventures.org/'>
                          <span class='ext-link'>www.rhiaventures.org</span>
                        </ExternalLink>
                        .
                      </p>
                      <div className='spacer30'></div>
                    </Col>
                    <Col xs='3' sm='3' md='3' lg='3'>
                      <img
                        className='img-responsive'
                        src={rhiaLogo}
                        alt='Rhia Ventures'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='9' sm='9' md='9' lg='9'>
                      <div className='spacer30 hidden-xs hidden-sm'></div>
                      <h2 className='blueColor'>The W.K. Kellogg Foundation</h2>
                      <p>
                        The W.K. Kellogg Foundation (WKKF), founded in 1930 as
                        an independent, private foundation by breakfast cereal
                        innovator and entrepreneur Will Keith Kellogg, is among
                        the largest philanthropic foundations in the United
                        States. Guided by the belief that all children should
                        have an equal opportunity to thrive, WKKF works with
                        communities to create conditions for vulnerable children
                        so they can realize their full potential in school, work
                        and life. For more information, visit{' '}
                        <ExternalLink href='http://www.wkkf.org/'>
                          <span class='ext-link'>www.wkkf.org</span>
                        </ExternalLink>
                        .
                      </p>
                      <div className='spacer60'></div>
                    </Col>
                    <Col xs='3' sm='3' md='3' lg='3'>
                      <img
                        className='img-responsive'
                        src={kelloggLogo}
                        alt='The W.K. Kellogg Foundation'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='9' sm='9' md='9' lg='9'>
                      <div className='spacer30 hidden-xs hidden-sm'></div>
                      <h2 className='blueColor'>Skyline Foundation</h2>
                      <p>
                      Skyline Foundation funds local, national and global social justice organizations tackling problems at their root and shifting systems toward a more equitable future. The foundation focuses on providing flexible, core, long-term support in order to build more resilient and sustainable non-profit ecosystems addressing the foundation’s target issues which include: birth justice, climate change, educational equity and public interest journalism. For more information, visit<ExternalLink href='https://skylinefoundation.org/'><span class='ext-link'> https://skylinefoundation.org/ </span></ExternalLink>
                      </p>

                      <div className='spacer60'></div>
                    </Col>
                    <Col xs='3' sm='3' md='3' lg='3'>
                      <img
                        className='img-responsive'
                        src={skyline}
                        alt='Skyline Foundation'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12' sm='12' md='12' lg='12'>
                      <p>
                        The Nicholson Foundation is now{' '}
                        <ExternalLink href='https://www.thenicholsonfoundation.org/news-and-resources/farewell-message-jan-nicholson-president.html'>
                          <span class='ext-link'>closed</span>.{' '}
                        </ExternalLink>
                        They supported the first round of grants of the Safer
                        Childbirth Cities Initiative.
                      </p>
                      <div className='spacer20'></div>
                    </Col>
                    <Col xs='12' sm='12' md='12' lg='12'>
                      <p>
                        If you are interested in learning more about joining the
                        co-funder collective, please{' '}
                        <a href='mailto:CSTMFORMOTHERS@merck.com?subject=Safer Childbirth Cities Co-funder Interest'>
                          drop us a line
                        </a>
                        .{' '}
                      </p>
                      <div className='spacer60'></div>
                    </Col>
                  </Row>
                </Container>
              </section>
              <Welcome
                siteTitle={data.site.siteMetadata.title}
                classes='blueColorBkgd'
              />
            </Layout>
          </>
        )}
      />
    );
  }
}

export default CoFunders;
